import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import HashLoader from 'react-spinners/HashLoader'

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        " https://serverless-api.sarvesh-rajdev.workers.dev/posts"
      );
      const postsResp = await resp.json();
      let jsonMap = postsResp.map(JSON.parse);
      console.log(jsonMap)
      setLoading(false);
      setPosts(jsonMap);
    };

    getPosts();
  }, []);

  return (
    <div>
        <Navbar expand="lg" variant="dark" bg="dark" className='mb-4'>
            <Container>
                <Navbar.Brand href="/">Sarvesh Social Media</Navbar.Brand>
                <Nav>
                    <Nav.Link href="/create">Create Post</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
        <Container>
          
            <h1>Posts</h1>
            {loading && <Container className="mt-5 d-flex justify-content-center"> <HashLoader color={"#FFFFFF"} loading={loading} size={100} /> </Container>}
            {posts.map((post) => (
                <div key={post.id}>
                    <Card className='m-2' bg='dark' text='white'>
                        <Card.Header>{post.username}</Card.Header>
                            <Card.Body>
                                <Card.Title> <Link to={`/posts/${post.id}`}>{post.title}</Link> </Card.Title>
                                <Card.Text>
                                {post.content}
                                </Card.Text>
                                <Button variant="primary">Upvote</Button>
                                <Button className="m-2" variant="secondary" >Downvote</Button>
                            </Card.Body>
                    </Card>
                </div>
            ))}
        </Container>
    </div>
  );
};

export default Posts;