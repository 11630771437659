
import './App.css';
import { Router } from "@reach/router";
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/css/styles.css'

import Posts from './components/posts'
import Post from './components/post'
import Create from './components/create'


function App() {
  return (
    <Router>
      <Posts path="/" />
      <Post path="/posts/:id" />
      <Create path="/create" />
    </Router>
  );
}

export default App;
