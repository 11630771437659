import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Redirect } from "@reach/router";

const Post = ({ id }) => {
  const [post, setPost] = useState({});
  const [image, setImage] = useState(false);
  const [text, setText] = useState(false);
  const [link, setLink] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = newComment;


    fetch(`https://serverless-api.sarvesh-rajdev.workers.dev/comments/${id}`, {
        method: 'POST',
        headers: {"Content-Type": "text/plain" },
        body: body
    }).then(() => {
        console.log('New Post Created');
        <Redirect to="/" />
    })


}

  useEffect(() => {
    const getPost = async () => {
      const resp = await fetch(
        `https://serverless-api.sarvesh-rajdev.workers.dev/posts/${id}`
      );
      const postResp = await resp.json();
      console.log("wah");
      console.log(postResp);
      setPost(JSON.parse(postResp));
      if (post.image !== '') {
        setImage(true);
      }
      if (post.content !== '') {
        setText(true);
      }
      if (post.link !== '') {
        setLink(true);
      }
    };

    const getComments = async () => {
      const resp = await fetch(
        `https://serverless-api.sarvesh-rajdev.workers.dev/comments/${id}`
      );
      const commentResp = await resp.text();
      const fetchComments = commentResp.split('#,#');
      console.log("cool");
      console.log(fetchComments);
      setComments(fetchComments);
    };

    getComments();
    getPost();
  }, [id]);

  if (!Object.keys(post).length) return <div />;

  return (
    <div>
        <Navbar expand="lg" variant="dark" bg="dark">
                <Container>
                    <Navbar.Brand href="/">Sarvesh Social Media</Navbar.Brand>
                    <Nav>
                        <Nav.Link href="/create">Create Post</Nav.Link>
                    </Nav>
                </Container>
        </Navbar>

        <Container className='mt-5'>

          <Card className='mt-1' bg='dark' text='white' border='primary'>
                          <Card.Header>{post.title}</Card.Header>
                              <Card.Body>
                                { text && <Card.Text>{post.content}</Card.Text>}
                                { link && <Card.Text> <Link to={post.link}> {post.link}</Link></Card.Text>}
                                { image && <Card.Img variant="bottom" src={post.image}/> }
                                <Card.Text className='pt-3'>
                                  <Link to="/">Go back</Link>
                                </Card.Text>
                              </Card.Body>
          </Card>

          {comments.map((comment, index) => (
                <div key={index}>
                    <Card className='mt-1' bg='dark' text='white'>
                        <Card.Header>User Anon</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                  {comment}
                                </Card.Text>
                            </Card.Body>
                    </Card>
                </div>
            ))}

          <Card className='mt-2' border='danger' bg='dark' text='white'>
            <Form onSubmit={handleSubmit} className="m-2">
                      <Form.Group className="mb-3" controlId="formBasicUser">
                          <Form.Label className="px-2">Enter Comment</Form.Label>
                          <Form.Control className="px-2" required type="text" placeholder="Enter Comment" onChange = {(e) => setNewComment(e.target.value)}/>
                      </Form.Group>

                      <Button variant="secondary" type="submit">
                          Submit
                      </Button>
                  </Form>
          </Card>

        </Container>
       
    </div>
  );
};

export default Post;