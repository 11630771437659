import React, { useEffect, useState} from "react";

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import { Redirect } from "@reach/router";

const Create = () => {
    const [title, setTitle] = useState('');
    const [username, setUser] = useState('');
    const [content, setContent] = useState('');
    const [link, setLink] = useState('');
    const [image, setImage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = {title, username, content, link, image}

        fetch("https://serverless-api.sarvesh-rajdev.workers.dev/posts", {
            method: 'POST',
            headers: {"Content-Type": "text/plain" },
            body: JSON.stringify(body)
        }).then(() => {
            console.log('New Post Created');
            <Redirect to="/" />
        })

    }

    const uploadImage = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setImage(base64);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
    
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    };

    return(
        <div>
            <Navbar expand="lg" variant="dark" bg="dark">
                <Container>
                    <Navbar.Brand href="/">Sarvesh Social Media</Navbar.Brand>
                    <Nav>
                        <Nav.Link href="/create">Create Post</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <Container className='mt-5'>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicUser">
                        <Form.Label style={{color:"white"}} >Username</Form.Label>
                        <Form.Control required type="text" placeholder="Enter Username" onChange = {(e) => setUser(e.target.value)}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicTitle">
                        <Form.Label style={{color:"white"}} >Title</Form.Label>
                        <Form.Control required type="text" placeholder="Enter Title" onChange = {(e) => setTitle(e.target.value)}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label style={{color:"white"}} >Enter Text</Form.Label>
                        <Form.Control as="textarea" rows={3} onChange = {(e) => setContent(e.target.value)}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicLink">
                        <Form.Label style={{color:"white"}} >Insert Link</Form.Label>
                        <Form.Control type="text" placeholder="Insert Link" onChange = {(e) => setLink(e.target.value)}/>
                    </Form.Group>

                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label style={{color:"white"}} >Upload Image</Form.Label>
                        <Form.Control type="file" accept="image/*" onChange = {(e) => {uploadImage(e);}} />
                    </Form.Group>

                    <Button variant="secondary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Container>
        </div>
    )
}

export default Create